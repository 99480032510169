import React from "react"

function HeaderLinks() {
    const links = [
        {'link': 'https://www.linkedin.com/in/dmitrij-wa%C5%9Bkowski-63370a58/', 'icon': 'fab fa-linkedin-in'},
        {'link': 'https://github.com/dwaskowski', 'icon': 'fab fa-github-alt'},
        {'link': 'https://bitbucket.org/dwaskowski', 'icon': 'fab fa-bitbucket'},
        {'link': 'https://stackoverflow.com/users/4791519/dwaskowski', 'icon': 'fab fa-stack-overflow'},
        {'link': 'https://twitter.com/dwaskowski', 'icon': 'fab fa-twitter'},
        {'link': 'https://www.facebook.com/dmitrij.waskowski', 'icon': 'fab fa-facebook-f'},
        {'link': 'skype:vas_dima', 'icon': 'fab fa-skype'}
    ];

    return links.map((link) => <li className="list-inline-item"><a href={link.link}><i className={link.icon} aria-hidden="true"></i></a></li>)
}

function HeaderInfo() {
    const links = [
        {'name': 'dimid.job@gmail.com', 'link': 'mailto:dimid.job@gmail.com', 'icon': 'fas fa-envelope'},
        {'name': '+1 847 877 5885', 'link': 'tel://+18478775885', 'icon': 'fas fa-phone'},
        {'name': 'Chicago, IL, USA', 'link': '#', 'icon': 'fas fa-map-marker-alt'}
    ];

    return links.map((link) => <li className="list-inline-item"><i className={link.icon}></i> <a href={link.link}>{link.name}</a></li>)
}

function ExpieriensYears() {
    const startDateYear = '2007';
    const expieriensYears = (new Date()).getFullYear() - startDateYear;
    return <b>{expieriensYears}</b>;
}

class Header extends React.Component  {
    render() {
        return (
            <header class="header">
                <div class="top-bar container-fluid">
                    <div class="actions">
                        <a class="btn d-none d-md-inline-block" href="mailto:dimid.job@gmail.com"><i class="fas fa-paper-plane" aria-hidden="true"></i> Drop me a line</a>
                        <a class="btn d-none d-md-inline-block" href="https://calendly.com/dwaskowski/30min" target="_blank"><i class="fas fa-calendar" aria-hidden="true"></i> Find a slot to call me</a>
                        <a class="btn" href="./cv/Dmitrij_Waskowski_CV.pdf"><i class="fas fa-download" aria-hidden="true"></i> Download My Resume</a>
                    </div>
                    <ul class="social list-inline">
                        <HeaderLinks />
                    </ul>
                </div>

                <div class="intro">
                    <div class="container text-center">
                        <img class="profile-image" src="./img/profile-image.png" alt="" />
                        <h1 class="name">Dmitrij Waskowski<span class="small">, MS</span></h1>
                        <div class="title"><b>Senior Software Engineer</b> <span class="small">| With <ExpieriensYears /> years exp in development</span></div>
                        <div class="subtitle"><b>Senior DevOps</b> | <b>Senior Software Reliability</b> | <b>Staff Software</b> </div>
                        <div class="profile">
                            <p>Multilingual, highly skilled, analytical professional with proven track record translating business requirements and functional specifications into logical program designs, code modules, stable application systems, code standards, and writing specs. In-depth expertise developing, configuring, and modifying complex integrated business and enterprise application solutions within various computing environments. Facilitate implementation and maintenance of complex business and enterprise software solutions to ensure successful deployment of released applications.</p>
                            <p>Support systems integration testing (SIT) and user acceptance testing (UAT). Translate business needs into technology solutions. Comprehensive background in Systems Analysis, Software Engineering, Design Patterns, Full Software Development Life Cycle, Team Leadership, Program Testing, and Problem Resolution.</p>
                            <p>Known for staying on top of emerging technology and software development trends. Strong leader able to coach team members in all software development lifecycle phases. Outstanding oral and written communication skills, successfully handle multiple projects simultaneously, work well in diverse environments and under pressure.</p>
                        </div>
                    </div>
                </div>

                <div class="contact-info">
                    <div class="container text-center">
                        <ul class="list-inline">
                            <HeaderInfo />
                        </ul>
                    </div>
                </div>

                <div class="page-nav-space-holder d-none d-md-block">
                    <div id="page-nav-wrapper" class="page-nav-wrapper text-center">
                        <div class="container">
                            <ul id="page-nav" class="nav page-nav list-inline">
                                <li class="nav-item"><a class="scrollto nav-link" href="#experiences-section">Experiences</a></li>
                                <li class="nav-item"><a class="scrollto nav-link" href="#skills-section">Technical proficiencies</a></li>
                                <li className="nav-item"><a className="scrollto nav-link" href="#education-section">Education</a></li>
                                <li class="nav-item"><a class="scrollto nav-link" href="#testimonials-section">Testimonials</a></li>
                                {/*<li class="nav-item"><a class="scrollto nav-link" href="#portfolio-section">Portfolio</a></li>*/}
                                <li class="nav-item"><a class="scrollto nav-link" href="#contact-section">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </header>
        )
    }
}

export default Header
