import React from "react"

class Testimonials extends React.Component  {
    render() {
        return (
            <section id="testimonials-section" className="testimonials-section section">
                <h2 className="section-title">Testimonials</h2>

                <div id="testimonials-carousel" className="testimonials-carousel carousel slide" data-interval="8000">

                    <ol className="carousel-indicators">
                        <li data-target="#testimonials-carousel" data-slide-to="0" className="active"></li>
                        <li data-target="#testimonials-carousel" data-slide-to="1"></li>
                        <li data-target="#testimonials-carousel" data-slide-to="2"></li>
                    </ol>

                    <div className="carousel-inner">
                        <div className="item carousel-item active">
                            <blockquote className="quote">
                                <p><i className="fas fa-quote-left"></i> Innovation distinguishes between a leader and a follower.
                                </p>
                            </blockquote>
                            <div className="source">
                                <div className="name">Steve Jobs</div>
                                <div className="position">CEO, Apple</div>
                            </div>

                        </div>
                        <div className="item carousel-item">
                            <blockquote className="quote">
                                <p><i className="fas fa-quote-left"></i> If you get up in the morning and think the future is going to be better, it is a bright day. Otherwise, it's not
                                </p>

                            </blockquote>
                            <div className="source">
                                <div className="name">Elon Musk</div>
                                <div className="position">CEO, Tesla</div>
                            </div>

                        </div>
                        <div className="item carousel-item">
                            <blockquote className="quote">
                                <p><i className="fas fa-quote-left"></i> Most entrepreneurial ideas will sound crazy, stupid and uneconomic, and then they’ll turn out to be right.
                                </p>
                            </blockquote>
                            <div className="source">
                                <div className="name">Reed Hastings</div>
                                <div className="position">CEO, Netflix</div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
        )
    }
}

export default Testimonials
