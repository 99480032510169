import React from "react"

function MainSkills() {
    const skills = [
        {
            'name': 'Software Development',
            'from': '2007',
            'desc': 'PHP & Symfony & Symfony Flex, NodeJS & TypeScript & GraphQL, Rest API',
            'procentage': 98
        },
        {
            'name': 'Cloud Computing',
            'from': '2014',
            'desc': 'AWS, K8s, Docker, SaltStack, Terraform, Travis CI, Sonar Qube, Jenkins, Capistrano',
            'procentage': 95
        },
        {
            'name': 'Artificial Intelligence',
            'from': '2016',
            'desc': 'Python, DialogFlow, AWS Lex',
            'procentage': 25
        }
    ];
    const yearNow = (new Date()).getFullYear();

    return skills.map((skill) => <div className="item col-12 col-md-4">
        <div className="item-inner">
            <div className="chart-easy-pie text-center">
                <div className="chart-theme-1 chart" data-percent={skill.procentage}><span>{skill.procentage}</span>%
                    <canvas height="110" width="110"></canvas>
                </div>
            </div>
            <h4 className="skill-name">{skill.name}</h4>
            <div className="level"><b>{(yearNow-skill.from)}</b> years exp</div>
            <div className="desc">{skill.desc}</div>
        </div>
    </div>)
}

function LanuageSkills() {
    const skills = [
        {
            'name': 'PHP',
            'from': '2007',
            'procentage': 98
        },
        {
            'name': 'NodeJS',
            'from': '2014',
            'procentage': 90
        },
        {
            'name': 'Python',
            'from': '2010',
            'procentage': 90
        },
        {
            'name': 'Bash',
            'from': '2009',
            'procentage': 98
        },
        {
            'name': 'GO',
            'from': '2018',
            'procentage': 25
        },
        {
            'name': 'Ruby',
            'from': '2014',
            'procentage': 15
        }
    ];
    const yearNow = (new Date()).getFullYear();

    return skills.map((skill) => <div className="item col-12 col-md-2">
        <div className="item-inner">
            <div className="chart-easy-pie text-center">
                <div className="chart-theme-1 chart-small" data-percent={skill.procentage}><span>{skill.procentage}</span>%
                    <canvas height="55" width="55"></canvas>
                </div>
            </div>
            <h4 className="skill-name">{skill.name}</h4>
            <div className="level"><b>{(yearNow-skill.from)}</b> years exp</div>
        </div>
    </div>)
}

function OtherSkills() {
    const skills = [
        {'name': 'RKE2', 'priority': true}, {'name': 'Longhorn', 'priority': true}, {'name': 'PHP', 'priority': true}, {'name': 'NodeJS', 'priority': true}, {'name': 'JavaScript', 'priority': true}, {'name': 'Python', 'priority': true}, {'name': 'GOlang', 'priority': true}, {'name': 'Ruby'}, {'name': 'Bash'},
        {'name': 'HTML'}, {'name': 'FBML'}, {'name': 'CSS'}, {'name': 'LESS'}, {'name': 'Symfony Flex', 'priority': true}, {'name': 'Symfony', 'priority': true}, {'name': 'API Platform', 'priority': true},
        {'name': 'Zend Framework', 'priority': true}, {'name': 'Express', 'priority': true}, {'name': 'Apollo', 'priority': true}, {'name': 'Restify'}, {'name': 'Slim Framework'}, {'name': 'Laravel'},
        {'name': 'CodeIgniter'}, {'name': 'Twig', 'priority': true}, {'name': 'Smarty'}, {'name': 'Blade'}, {'name': 'MongoDB', 'priority': true}, {'name': 'MySQL', 'priority': true},
        {'name': 'Firebird'}, {'name': 'Doctrine', 'priority': true}, {'name': 'Propel'}, {'name': 'Redis', 'priority': true}, {'name': 'APC'}, {'name': 'Memcache'}, {'name': 'DialogFlow'},
        {'name': 'AWS Lex'}, {'name': 'Puppeteer', 'priority': true}, {'name': 'PHP V8Js'}, {'name': 'AWS SQS', 'priority': true}, {'name': 'AWS Route53 with Trafc Policy', 'priority': true}, {'name': 'AWS DynamoDB', 'priority': true},
        {'name': 'Apache Kafka', 'priority': true}, {'name': 'RabbitMQ', 'priority': true}, {'name': 'Travis CI', 'priority': true}, {'name': 'Bamboo', 'priority': true}, {'name': 'Sonar Qube', 'priority': true}, {'name': 'Jenkins', 'priority': true}, {'name': 'Capistrano'},
        {'name': 'K8s', 'priority': true}, {'name': 'Docker', 'priority': true}, {'name': 'Ambassador'}, {'name': 'Docker Swarm'}, {'name': 'SaltStack'}, {'name': 'Puppet', 'priority': true}, {'name': 'Terraform', 'priority': true}, {'name': 'Terragrunt', 'priority': true},
        {'name': 'Apache Ant'}, {'name': 'Phing'}, {'name': 'Rest API', 'priority': true}, {'name': 'SwaggerAPI/OpenAPI'}, {'name': 'Grafana', 'priority': true}, {'name': 'Prometheus', 'priority': true},
        {'name': 'Kibana', 'priority': true}, {'name': 'ELK'}, {'name': 'Unit Tests', 'priority': true}, {'name': 'Jest', 'priority': true}, {'name': 'Codeception'}, {'name': 'Behat Tests', 'priority': true}, {'name': 'Google Tag Manager', 'priority': true},
        {'name': 'Apache NiFi', 'priority': true}, {'name': 'GraphQL', 'priority': true}, {'name': 'Rackspace'}, {'name': 'Linode'}, {'name': 'Bootstrap'}, {'name': 'jQuery'}, {'name': 'Git', 'priority': true}, {'name': 'SVN'}
    ];

    return skills.map((skill) => <span className={skill.priority === true ? "skill-tag skill-tag-extra" : "skill-tag"}>{skill.name}</span>)
}

class Skills extends React.Component  {
    render() {
        return (
            <section id="skills-section" className="skills-section section text-center">
                <h2 className="section-title">Technical proficiencies</h2>
                <div className="top-skills">
                    <h3 className="subtitle">Top Skills</h3>
                    <div className="row">
                        <MainSkills />
                    </div>
                </div>

                <div className="top-skills">
                    <h3 className="subtitle">Language Skills</h3>
                    <div className="row">
                        <LanuageSkills />
                    </div>
                </div>

                <div className="other-skills">
                    <h3 className="subtitle">Other Skills</h3>
                    <div className="misc-skills">
                        <OtherSkills />
                    </div>
                </div>

            </section>
        )
    }
}

export default Skills
