import React from "react"

function ExperienceList() {
    const experiences = [
        {
            'company': 'OpticsPlanet Inc.',
            'companyDescription': 'One of the leading online shops in the world covering all of your outdoor and tactical needs, you can get optics, gun parts, shooting accessories, ammunition, hunting and outdoor gear, apparel and footwear, and exclusive products.',
            'location': 'Northbrook, IL, USA',
            'position': 'DevOps Engineer',
            'period': '2021 – present',
            'desc': 'Automate and streamline operations and processes.',
            'descExtras': [
                'Saved time on delivering to production/development by automating infrastructure via Terragrunt/Terraform for AWS, Azure, and self-hosted clusters.',
                'Successfully create configuration via Puppet for automagical provision VMs and self-hosted Kubernetes clusters based on RKE2.',
                'Successfully created a lot of CI/CD pipelines using Bamboo for different kinds of projects based on .Net, PHP, Node.JS, etc.',
                'Created CI/CD scripts for building and deploying in different Kubernetes clusters based on AWS, AKS, and self-hosted.'
            ]
        },
        {
            'company': 'Flaconi GmbH',
            'companyDescription': 'One of the leading online beauty product shops in Germany covering all product segments, from drugstores to natural cosmetics to premium.',
            'location': 'Berlin, Germany',
            'position': 'Senior Software Engineer',
            'period': '2018 – 2021',
            'desc': 'Develop software solutions by studying information needs; conferring with users; studying systems flow, data usage, and work processes; investigate problem areas; follow the software development lifecycle. Determine operational feasibility by evaluating analysis, problem definition, requirements, solution development, and proposed solutions.',
            'descExtras': [
                'Transitioned e-commerce monolith applications to small micro-services using PHP, Symfony, API Platform, NodeJS, Typescript, Apollo, GraphQL, Rest API and Domain Driven Design to receive failure stable system.',
                'Implemented Apache NiFi flows 100%; created setup scripts for local kubernates environment 100%.',
                'Gave more stability to system by organizing data traffic between systems via Apache NiFi and Apache Kafka.',
                'Saved time on delivering services to production by automating infrastructure via Terraform and creating configurations for CI and CD using TravisCI, SonarQube, K8s and Ambassador.',
                'Successfully created micro-services for Service Side Rendering using Puppeteer for speed upload time of pages.',
                'Created: dashboards that monitor statuses of services which give whole picture about system; 10 Grafana dashboards for monitoring services metrics, 100%; and CI/CD pipelines using TravisCI, Jenkins for microservices.'
            ]
        },
        {
            'company': 'Contorion GmbH',
            'companyDescription': 'Innovative, fast-growing online shop for small to mid-size professional industrial and trade supply businesses.',
            'location': 'Berlin, Germany',
            'position': 'Senior Software Engineer',
            'period': '2016 – 2018',
            'desc': 'Improved operations by conducting systems analysis; recommended changes in policies and procedures. Documented and demonstrated solutions by developing flowcharts, layouts, diagrams, charts, code comments, and clear code. Prepared and installed solutions by determining and designing system specifications, standards, and programming.',
            'descExtras': [
                'Architected databases in MySQL and MongoDB to reach stable structure.',
                'Organised data traffic via RabbitMQ queues to give more independency between systems.',
                'Automated infrastructure via SaltStack and wrote scripts in Python and Bash which saved time maintaining new servers in different environments.',
                'Integrated: services with 3rd party API for Cart; and feed data with system, 100%.',
                'Created CI/CD pipelines using Jenkins.'
            ]
        },
        {
            'company': 'Auto1 Group',
            'companyDescription': 'Europe\'s leading digital automotive platform.',
            'location': 'Berlin, Germany',
            'position': 'Senior DevOps/Software Engineer',
            'period': '2014 – 2016',
            'desc': 'Collaborated with Software Engineers to help them deploy and operate different systems. Helped automate and streamline operations and processes. Built functional systems that improved customer experiences. Deployed product updates, identified production issues, and implemented integrations that meet customer needs.',
            'descExtras': [
                'Developed high-performance e-commerce applications and micro-services using PHP, Symfony.',
                'These improvements helped company grow faster with stable environments and delivery pipelines for services.',
                'Relocated company environment from multiple providers into modern Amazon stack using Terefrom for full automatization.'
            ]
        }

    ];

    return experiences.map((experience) => <div className="item">
            <div className="work-place">
                <h3 className="place">{experience.company}</h3>
                <div className="location">
                    <i className="fas fa-map-marker-alt mr-1"></i> {experience.location}
                </div>
            </div>
            <div className="job-meta">
                <div className="title">{experience.position}</div>
                <div className="time">{experience.period}</div>
            </div>
            <div className="job-desc">
                <p><i>{experience.companyDescription}</i></p>
                <p>{experience.desc}</p>
                {experience.descExtras.length > 0 &&
                    <ul>
                        {experience.descExtras.map((descExtra) => <li>{descExtra}</li>)}
                    </ul>
                }
            </div>
        </div>
    )
}

class Experience extends React.Component  {
    render() {
        return (
            <section id="experiences-section" className="experiences-section section">
                <h2 className="section-title">Professional Experiences</h2>
                <div className="timeline">
                    <ExperienceList />
                </div>

            </section>
        )
    }
}

export default Experience
