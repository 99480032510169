import React from "react"

function AdditionalExperienceList() {
    const experiences = [
        {
            'company': 'NEGON S.C.',
            'companyDescription': 'Interactive agency utilizing create, innovative CMS systems, e-commerce, and web hosting.',
            'location': 'Cracow, Poland',
            'position': 'President/CTO',
            'period': '2010 – 2016',
            'desc': 'Developed and managed processes to create innovative web applications for clients in different branches utilizing newest technologies to create optimal solutions. Completely created system CMS (AriloWeb) for managing HTML and Flash web pages, 100%. Created 15 +/- web projects using AriloWeb CMS.',
            'descExtras': []
        },
        {
            'company': 'Omega Meble Sp. z o.o.',
            'companyDescription': 'Creative, fast-growing online shop for sale and configuration of furniture.',
            'location': 'Wieliczka, Polan',
            'position': 'Senior Software Engineer',
            'period': '2013 – 2014',
            'desc': 'Reorganised and rebuilt shop to accommodate new e-commerce platform using PHP, Laravel and Doctrine that gave customer faster and user-friendly architecture. Created modules for new e-shop project.',
            'descExtras': []
        },
        {
            'company': 'AutoCentrum.pl S.A.',
            'companyDescription': 'Company creates and develops informational automotive websites.',
            'location': 'Cracow, Poland',
            'position': 'Senior Software Engineer',
            'period': '2010 – 2013',
            'desc': 'Developed and supported building high-performance automobile portals and services. Created fuel cost calculation system, 50%.',
            'descExtras': []
        },
        {
            'company': 'Grupa Euro',
            'companyDescription': 'Firm, previously interactive agency Eurohost, deals with wide spectrum of IT activities including programming, e-marketing. and e-commerce.',
            'location': 'Cracow, Poland',
            'position': 'Middle/Senior/Lead Software Engineer',
            'period': '2007 – 2010',
            'desc': 'Developed small innovative web applications for different branches. Managed projects and teams for create project in time. Contacted with clients for find, decide and take better solutions for project.Developed small innovative web applications for different branches. Managed projects and teams and delivered projects on time and within budget. Created nearly 30 web projects.',
            'descExtras': []
        }

    ];

    return experiences.map((experience) => <div className="item">
            <div className="work-place">
                <h3 className="place">{experience.company}</h3>
                <div className="location">
                    <i className="fas fa-map-marker-alt mr-1"></i> {experience.location}
                </div>
            </div>
            <div className="job-meta">
                <div className="title">{experience.position}</div>
                <div className="time">{experience.period}</div>
            </div>
            <div className="job-desc">
                <p><i>{experience.companyDescription}</i></p>
                <p>{experience.desc}</p>
                {experience.descExtras.length > 0 &&
                    <ul>
                        {experience.descExtras.map((descExtra) => <li>{descExtra}</li>)}
                    </ul>
                }
            </div>
        </div>
    )
}

class AdditionalExperience extends React.Component  {
    render() {
        return (
            <section id="experiences-section" className="experiences-section section">
                <h2 className="section-title">Additional Experiences</h2>
                <div className="timeline">
                    <AdditionalExperienceList />
                </div>

            </section>
        )
    }
}

export default AdditionalExperience
