import React from "react"

class Footer extends React.Component  {
    render() {
        return (
            <footer class="footer text-center">
                <div class="container">
                </div>
            </footer>
        )
    }
}

export default Footer
