import React from "react"

function ContactLinks() {
    const links = [
        {'link': 'https://www.linkedin.com/in/dmitrij-wa%C5%9Bkowski-63370a58/', 'icon': 'fab fa-linkedin-in'},
        {'link': 'https://github.com/dwaskowski', 'icon': 'fab fa-github-alt'},
        {'link': 'https://bitbucket.org/dwaskowski', 'icon': 'fab fa-bitbucket'},
        {'link': 'https://stackoverflow.com/users/4791519/dwaskowski', 'icon': 'fab fa-stack-overflow'},
        {'link': 'https://twitter.com/dwaskowski', 'icon': 'fab fa-twitter'},
        {'link': 'https://www.facebook.com/dmitrij.waskowski', 'icon': 'fab fa-facebook-f'},
        {'link': 'skype:vas_dima', 'icon': 'fab fa-skype'}
    ];

    return links.map((link) => <li className="list-inline-item"><a href={link.link}><i className={link.icon} aria-hidden="true"></i></a></li>)
}

function FollowTech() {
    const techs = [
        'Software development with Symfony/Symfony Flex/PHP',
        'Software development with NodeJS/TypeScript/GraphQL',
        'Cloud computing with K8s/Docker/Terraform/SaltStack',
        'CI/CD with Travis CI/Sonar Qube/Jenkins',
        'Artificial Intelligence with Python'
    ];

    return techs.map((tech) => <li><i className="fas fa-check" aria-hidden="true"></i> {tech}</li>)
}

class Contact extends React.Component  {
    render() {
        return (
            <section id="contact-section" className="contact-section section">
                <h2 className="section-title">Get in Touch</h2>
                <div className="intro">
                    <img className="profile-image"
                         src="./img/profile-image.png" alt="" />
                        <div className="dialog">
                            <p>I enjoy being challenged and engaging with projects that require me to work outside my comfort and knowledge set, as continuing to learn new languages and development techniques are important to me and the success of your organization.</p>
                            <p><strong>I can help with the following:</strong></p>
                            <ul className="list-unstyled service-list">
                                <FollowTech />
                            </ul>
                            <p>Drop me a line at <a href="mailto:dimid.job@gmail.com">dimid.job@gmail.com</a> or find a slot to call
                                me <a href="https://calendly.com/dwaskowski/30min" target="_blank">here</a></p>
                            <ul className="social list-inline">
                                <ContactLinks />
                            </ul>
                        </div>
                </div>

            </section>
        )
    }
}

export default Contact
