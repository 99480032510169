import React from "react"

class Portfolio extends React.Component  {
    render() {
        return ([]);
        // return (
        //     <section id="portfolio-section" className="portfolio-section section">
        //         <h2 className="section-title">Portfolio</h2>
        //         <ul id="filters" className="filters clearfix">
        //             <li className="type active" data-filter="*">All</li>
        //             <li className="type" data-filter=".backend">Back-end</li>
        //             <li className="type" data-filter=".frontend">Front-end</li>
        //         </ul>
        //         <div className="items-wrapper isotope row" style="position: relative; height: 621.562px;">
        //             <div className="item frontend col-lg-3 col-6" style="position: absolute; left: 0px; top: 0px;">
        //                 <div className="item-inner">
        //                     <figure className="figure">
        //                         <img className="img-fluid"
        //                              src="./img/portfolio-1.jpg" alt="" />
        //                     </figure>
        //                     <div className="content text-left">
        //                         <h3 className="sub-title"><a href="#">Project
        //                             Lorem Ipsum</a></h3>
        //                         <div className="meta">AngularJS</div>
        //                         <div className="action"><a href="#">View on
        //                             Github</a></div>
        //                     </div>
        //                     <a className="link-mask" href="#"></a>
        //                 </div>
        //             </div>
        //             <div className="item backend col-lg-3 col-6" style="position: absolute; left: 262.5px; top: 0px;">
        //                 <div className="item-inner">
        //                     <figure className="figure">
        //                         <img className="img-fluid"
        //                              src="./img/portfolio-2.jpg" alt="" />
        //                     </figure>
        //                     <div className="content text-left">
        //                         <h3 className="sub-title"><a href="#">Project
        //                             Lorem Ipsum</a></h3>
        //                         <div className="meta">Django</div>
        //                         <div className="action"><a href="#">View on
        //                             Github</a></div>
        //                     </div>
        //                     <a className="link-mask" href="#"></a>
        //                 </div>
        //             </div>
        //             <div className="item backend frontend col-lg-3 col-6"
        //                  style="position: absolute; left: 525px; top: 0px;">
        //                 <div className="item-inner">
        //                     <figure className="figure">
        //                         <img className="img-fluid"
        //                              src="./img/portfolio-3.jpg" alt="" />
        //                     </figure>
        //                     <div className="content text-left">
        //                         <h3 className="sub-title"><a href="#">Project
        //                             Lorem Ipsum</a></h3>
        //                         <div className="meta">Django/JavaScript</div>
        //                         <div className="action"><a href="#">View on
        //                             Github</a></div>
        //                     </div>
        //                     <a className="link-mask" href="#"></a>
        //                 </div>
        //             </div>
        //             <div className="item frontend col-lg-3 col-6" style="position: absolute; left: 787.5px; top: 0px;">
        //                 <div className="item-inner">
        //                     <figure className="figure">
        //                         <img className="img-fluid"
        //                              src="./img/portfolio-4.jpg" alt="" />
        //                     </figure>
        //                     <div className="content text-left">
        //                         <h3 className="sub-title"><a href="#">Project
        //                             Lorem Ipsum</a></h3>
        //                         <div className="meta">ReactJS</div>
        //                         <div className="action"><a href="#">View on
        //                             Github</a></div>
        //                     </div>
        //                     <a className="link-mask" href="#"></a>
        //                 </div>
        //             </div>
        //             <div className="item backend col-lg-3 col-6" style="position: absolute; left: 0px; top: 310.781px;">
        //                 <div className="item-inner">
        //                     <figure className="figure">
        //                         <img className="img-fluid"
        //                              src="./img/portfolio-5.jpg" alt="" />
        //                     </figure>
        //                     <div className="content text-left">
        //                         <h3 className="sub-title"><a href="#">Project
        //                             Lorem Ipsum</a></h3>
        //                         <div className="meta">Python</div>
        //                         <div className="action"><a href="#">View on
        //                             Github</a></div>
        //                     </div>
        //                     <a className="link-mask" href="#"></a>
        //                 </div>
        //             </div>
        //             <div className="item backend col-lg-3 col-6"
        //                  style="position: absolute; left: 262.5px; top: 310.781px;">
        //                 <div className="item-inner">
        //                     <figure className="figure">
        //                         <img className="img-fluid"
        //                              src="./img/portfolio-6.jpg" alt="" />
        //                     </figure>
        //                     <div className="content text-left">
        //                         <h3 className="sub-title"><a href="#">Project
        //                             Lorem Ipsum</a></h3>
        //                         <div className="meta">JavaScript</div>
        //                         <div className="action"><a href="#">View on
        //                             Github</a></div>
        //                     </div>
        //                     <a className="link-mask" href="#"></a>
        //                 </div>
        //             </div>
        //
        //             <div className="item frontend col-lg-3 col-6"
        //                  style="position: absolute; left: 525px; top: 310.781px;">
        //                 <div className="item-inner">
        //                     <figure className="figure">
        //                         <img className="img-fluid"
        //                              src="./img/portfolio-7.jpg" alt="" />
        //                     </figure>
        //                     <div className="content text-left">
        //                         <h3 className="sub-title"><a href="#">Project
        //                             Lorem Ipsum</a></h3>
        //                         <div className="meta">HTML/AngularJS</div>
        //                         <div className="action"><a href="#">View on
        //                             Github</a></div>
        //                     </div>
        //                     <a className="link-mask" href="#"></a>
        //                 </div>
        //             </div>
        //
        //             <div className="item frontend backend col-lg-3 col-6"
        //                  style="position: absolute; left: 787.5px; top: 310.781px;">
        //                 <div className="item-inner">
        //                     <figure className="figure">
        //                         <img className="img-fluid"
        //                              src="./img/portfolio-8.jpg" alt="" />
        //                     </figure>
        //                     <div className="content text-left">
        //                         <h3 className="sub-title"><a href="#">Project
        //                             Lorem Ipsum</a></h3>
        //                         <div className="meta">Python/AngularJS</div>
        //                         <div className="action"><a href="#">View on
        //                             Github</a></div>
        //                     </div>
        //                     <a className="link-mask" href="#"></a>
        //                 </div>
        //             </div>
        //
        //         </div>
        //
        //     </section>
        // )
    }
}

export default Portfolio
