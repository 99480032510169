import React from "react"

function EducationList() {
    const educations = [
        {
            'title': 'Master of Science in Applied Computer Science (MS)',
            'university': 'Cracow University of Technology',
            'years': 'Cracow, Poland, 2012',
            'desc': 'MVC Model-Based Framework Build and Implementation in Web Application'
        },
        {
            'title': 'Bachelor of Engineering in Applied Computer Science (BEng)',
            'university': 'Cracow University of Technology',
            'years': 'Cracow, Poland, 2008',
            'desc': 'A city information system'
        }
    ];

    return educations.map((education) => <div className="item col-12 col-md-6">
        <div className="item-inner">
            <h3 className="degree">{education.title}</h3>
            <div className="education-body">{education.university}</div>
            <div className="time">{education.years}</div>
            <div className="desc">Thesis: <i>{education.desc}</i></div>
        </div>
    </div>)
}

class Education extends React.Component  {
    render() {
        return (
            <section id="education-section" className="education-section section">
                <h2 className="section-title">Education</h2>
                <div className="row">
                    <EducationList />
                </div>
            </section>
        )
    }
}

export default Education
