import React from "react"
import Footer from "./components/Footer/Footer"
import Header from "./components/Header/Header"
import Experience from "./components/Experience/Experience"
import Education from "./components/Education/Education"
import Skills from "./components/Skills/Skills"
import Testimonials from "./components/Testimonials/Testimonials"
import Portfolio from "./components/Portfolio/Portfolio"
import Contact from "./components/Contact/Contact"
import AdditionalExperience from "./components/Experience/AdditionalExperience";

class App extends React.Component  {
    render() {
        return (
            [
                <Header />,
                    <div class="wrapper container">
                        <Experience />
                        <AdditionalExperience />
                        <Skills />
                        <Education />
                        <Testimonials />
                        <Portfolio />
                        <Contact />
                    </div>,
                <Footer />
            ]
        )
    }
}

export default App
